import * as React from "react"
import { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Location } from "../components/location"
import phone from "../images/icons/phone.svg"
import homePhone from "../images/icons/home-phone.svg"
import envelope from "../images/icons/envelope.svg"

function Kontakt() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSentSuccess, setFormSentSuccess] = useState(false);
  const [formSentError, setFormSentError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    fetch(form.action, { 
      method: form.method,
      body: formData
    })
    .then(res => {
      if (res.ok) {
        setName('');
        setEmail('');
        setMessage('')
        setFormSentSuccess(true);
      }
      setFormSentError(true);
      throw new Error('Coś poszło nie tak')
    })
    .catch(err => {
      setName('');
      setEmail('');
      setMessage('')
      setFormSentError(true);
    })
  }

  const sendButtonTemplate = () => {
    return (
      <button type="submit" className="py-4 px-8 border-4 rounded-full border-primary hover:bg-primary 
      text-primary font-bold hover:text-white transition duration-150 mr-6">Wyślij</button>
    )
  }

  const messageBox = () => {
    if (formSentSuccess) {
      return (
        <div className="bg-green-100 rounded-full py-4 px-8 text-green-600 font-bold">Wiadomość wysłano pomyślnie!</div>
      )
    }
    if (formSentError) {
      return (
        <div className="bg-red-100 rounded-full py-4 px-8 text-red-600 font-bold">Coś poszło nie tak, spróbuj jeszcze raz</div>
      )
    }
  }

  return (
    <Layout>
      <Seo title="Kontakt" />
      {/* <div className="container py-20">
        <h1 className="font-bold text-4xl text-secondary mb-4" style={{ marginLeft: '-5px'}}>Kontakt</h1>
        <div className="border-b-8 border-secondary w-12 mb-12"></div>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <form action="message.php" method="POST" onSubmit={handleSubmit} style={{ maxWidth: 700}}>
            <div className="flex-wrap md:flex-nowrap flex mb-6">
              <input required className="w-full md:w-1/2 mb-3 md:mb-0 mr-0 md:mr-3 border-4 border-primary rounded-lg" placeholder="Imię i Nazwisko" name="name" type="text" onChange={(e) => setName(e.target.value)} />
              <input required className="w-full md:w-1/2 ml-0 md:ml-3 border-4 border-primary rounded-lg" placeholder="Email" name="email" type="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="flex mb-6">
              <textarea required className="w-full border-4 border-primary rounded-lg" placeholder="Wiadomość" name="message" cols="30" rows="6" onChange={(e) => setMessage(e.target.value)} ></textarea>
            </div>
            <div className="flex items-center">
             {sendButtonTemplate()}
             {messageBox()}
            </div>
          </form>
          <div className="flex flex-col">
            <div className="py-12 md:py-0 flex justify-start">
              <div className="flex flex-col text-body">
                <span className="font-bold mb-2">F.P.H.U Wojmal</span>
                <span className="mb-2">Robert Wojtycza</span>
                <span className="mb-2">ul. Sienkiewicza 32</span>
                <span>32-400 Myślenice</span>
                <div className="flex flex-col mt-6">
                  <div className="mb-2 flex items-center"><img src={phone} className="mr-2" /><span>606 135 787</span></div>
                  <div className="mb-2 flex items-center"><img src={homePhone} className="mr-2"/><span>12 274 20 84</span></div>
                  <div className="flex items-center"><img src={envelope} className="mr-2"/><span>biuro@wojmal.pl</span></div>
                </div>
              </div>
            </div>
          </div>
      </div>
      </div> */}
      <div className="container py-20 flex flex-col flex-1 justify-center">
        <h1 className="font-bold text-4xl text-secondary mb-4" style={{ marginLeft: '-5px'}}>Kontakt</h1>
        <div className="border-b-8 border-secondary w-12 mb-12"></div>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div className="py-12 md:py-0 flex">
            <div className="flex flex-col text-body">
              <span className="font-bold mb-2">F.P.H.U Wojmal</span>
              <span className="mb-2">Robert Wojtycza</span>
              <span className="mb-2">ul. Sienkiewicza 32</span>
              <span>32-400 Myślenice</span>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex flex-col">
              <div className="mb-2 flex items-center"><img src={phone} className="mr-2" /><span>606 135 787</span></div>
              <div className="mb-2 flex items-center"><img src={homePhone} className="mr-2"/><span>12 274 20 84</span></div>
              <div className="flex items-center"><img src={envelope} className="mr-2"/><span>biuro@wojmal.pl</span></div>
            </div>
          </div>
        </div>
      </div>
      <Location />
    </Layout>
  )
}

export default Kontakt
